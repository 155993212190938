<template>
  <v-container v-if="getProfile" class="userprofile--parent--container pt-0">
    <v-container v-if="isUpdateProsuccess">
      <div class="userprofile--success--dialog">
        <div class="d-flex justify-center flex-column">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="white"
            elevation="0"
            width="38"
            height="38"
          >
            <v-icon color="black">
              mdi-check
            </v-icon>
          </v-btn>
        </div>
        <span
          class="text--medium--14 userprofile--success--dialog--text"
          style="padding-left: 18px;"
        >
          ส่งคำขอแก้ไขข้อมูลสำเร็จ เจ้าหน้าที่จะดำเนินการแก้ไขภายใน 3-5
          วันทำการ</span
        >
      </div>
    </v-container>
    <v-container v-if="isUpdateProsuccess === false">
      <div class="userprofile--fail--dialog">
        <div class="d-flex justify-center flex-column">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="white"
            elevation="0"
            width="38"
            height="38"
          >
            <v-icon color="black">
              mdi-cancel
            </v-icon>
          </v-btn>
        </div>
        <span
          class="text--medium--14 userprofile--success--dialog--text"
          style="padding-left: 18px; color: white;"
        >
          {{ errorMessage }}
        </span>
      </div>
    </v-container>

    <v-container class="userprofile--fail--dialog--text--mobile pt-0 pb-0">
      <span class="mb-0 text--bold--14" style="color: #b71c1c;">{{
        errorMessage
      }}</span>
    </v-container>

    <v-row no-gutters class="userprofile--row--info">
      <v-col xs="12" sm="8" offset-sm="2" md="8" offset-md="2">
        <p class="mb-0 user-profile-header">บัญชีโปรไฟล์</p>
        <p class="text--regular--16 userprofile--title--text">
          การแก้ไขข้อมูลส่วนตัวหรือข้อมูลที่อยู่ กรุณากด “ส่งคำขอแก้ไขข้อมูล”
          เพื่อส่งคำขอการแก้ไข
        </p>
        <v-divider class="userprofile--divider"></v-divider>
        <div class="userprofile--info--title">
          <p class="mb-0 user-profile-personal-information">ข้อมูลส่วนตัว</p>
        </div>
        <div
          class="d-flex"
          v-if="loginType === 'google' || loginType == 'facebook'"
        >
          <div>
            <p class="text--bold--16">
              เข้าสู่ระบบโดยใช้แอคเค้าท์
            </p>
          </div>
          <div class="ml-2">
            <img
              v-if="loginType === 'facebook'"
              src="../assets/fb-logo.png"
              width="23"
              height="23"
              class="loginform-btn-icon-padding"
            />
            <img
              v-if="loginType === 'google'"
              src="../assets/gmail-logo.svg"
              width="22"
              height="22"
              class="loginform-btn-icon-padding"
            />
          </div>
        </div>

        <v-card class="mx-auto" outlined>
          <v-container class="userprofile--container">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <p class="mb-0 text--bold--16">ชื่อ - นามสกุล</p>
                <p
                  class="text--regular--16 userprofile--info--text"
                  v-if="!isEditDesktop && !isEditMobileInfo"
                >
                  {{ getProfile.fullName || "" }}
                </p>
                <div
                  class="userprofile--edit--text"
                  v-if="isEditDesktop || isEditMobileInfo"
                >
                  <v-text-field
                    class="text--regular--placeholder--14"
                    required
                    single-line
                    outlined
                    dense
                    v-model="getProfile.fullName"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <p class="mb-0 text--bold--16">เบอร์โทรติดต่อ</p>
                <input
                  type="text"
                  v-mask="'###-###-####'"
                  v-model="getProfile.mobile"
                  v-if="!isEditDesktop && !isEditMobileInfo"
                  class="userprofile--info--text"
                />
                <div
                  class="userprofile--edit--text"
                  v-if="isEditDesktop || isEditMobileInfo"
                >
                  <v-text-field
                    class="text--regular--placeholder--14"
                    v-mask="'###-###-####'"
                    required
                    single-line
                    outlined
                    dense
                    v-model="getProfile.mobile"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <p class="mb-0 text--bold--16">อีเมล</p>
                <p
                  v-if="
                    !isEditDesktop && !isEditMobileInfo && !isEditMobileAddress
                  "
                  class="text--regular--16 userprofile--info--text"
                >
                  {{ getProfile.email || "" }}
                </p>
                <div
                  class="userprofile--edit--text"
                  v-if="isEditDesktop || isEditMobileInfo"
                >
                  <v-text-field
                    class="text--regular--placeholder--14"
                    required
                    single-line
                    outlined
                    dense
                    v-model="getProfile.email"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="
                  !isEditDesktop &&
                    loginType !== 'google' &&
                    loginType !== 'facebook'
                "
              >
                <p class="mb-0 text--bold--16">รหัสผ่าน</p>
                <div class="d-flex flex-row userprofile--password--container">
                  <p>************</p>
                  <p
                    class="pl-15 primary--text text--regular--16"
                    style=" cursor: pointer;"
                    @click="onClickForgotPassword"
                  >
                    เปลี่ยนรหัสผ่าน
                  </p>
                </div>
              </v-col>
              <!-- <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="isEditDesktop"
              >
                <p class="mb-0 text--bold--16">รหัสผ่าน</p>
                <div class="d-flex flex-row userprofile--password--container">
                  <p>************</p>
                  <p
                    class="pl-15 primary--text text--regular--16"
                    style=" cursor: pointer;"
                    @click="onClickForgotPassword"
                  >
                    เปลี่ยนรหัสผ่าน
                  </p>
                </div>
              </v-col> -->
            </v-row>
          </v-container>
        </v-card>

        <div v-if="isEditMobileInfo" class="userprofile--button--submit--info">
          <v-btn
            elevation="0"
            medium
            rounded
            height="50"
            width="217"
            color="primary"
            @click="onSubmitEditProfile"
            class="pl-16 pr-16"
          >
            <span style="padding: 16px 47px 13px 48px; color: black;"
              >บันทึก</span
            ></v-btn
          >
        </div>

        <div style="margin-top: 37px;">
          <div class="userprofile--info--address">
            <p class="user-profile-personal-information">ข้อมูลที่อยู่</p>
            <!-- <v-btn
              rounded
              outlined
              @click="onEditProfileMobileAddress"
              elevation="0"
              class="text--regular--14 hidden-md-and-up user-profile-personal-button"
            >
              แก้ไขข้อมูล
            </v-btn> -->
          </div>
          <v-card class="mx-auto" outlined>
            <v-container class="userprofile--container">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--16">เลขที่อยู่</p>
                  <p
                    class="text--regular--16"
                    v-if="!isEditDesktop && !isEditMobileAddress"
                  >
                    {{ getProfile.address || "" }}
                  </p>
                  <div
                    class="userprofile--edit--text"
                    v-if="isEditDesktop || isEditMobileAddress"
                  >
                    <v-text-field
                      class="text--regular--placeholder--14"
                      required
                      single-line
                      outlined
                      dense
                      v-model="getProfile.address"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--16">ถนน</p>
                  <p
                    v-if="!isEditDesktop && !isEditMobileAddress"
                    class="text--regular--16"
                  >
                    {{ getProfile.road || "" }}
                  </p>
                  <div
                    class="userprofile--edit--text"
                    v-if="isEditDesktop || isEditMobileAddress"
                  >
                    <v-text-field
                      class="text--regular--placeholder--14"
                      required
                      single-line
                      outlined
                      dense
                      v-model="getProfile.road"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--16">แขวง / ตำบล</p>
                  <p
                    class="text--regular--16"
                    v-if="!isEditDesktop && !isEditMobileAddress"
                  >
                    {{ getProfile.subdistrict || "" }}
                  </p>
                  <div
                    class="userprofile--edit--text"
                    v-if="isEditDesktop || isEditMobileAddress"
                  >
                    <v-text-field
                      class="text--regular--placeholder--14"
                      required
                      single-line
                      outlined
                      dense
                      v-model="getProfile.subdistrict"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--16">เขต / อำเภอ</p>
                  <p
                    class="text--regular--16"
                    v-if="!isEditDesktop && !isEditMobileAddress"
                  >
                    {{ getProfile.district || "" }}
                  </p>
                  <div
                    class="userprofile--edit--text"
                    v-if="isEditDesktop || isEditMobileAddress"
                  >
                    <v-text-field
                      class="text--regular--placeholder--14"
                      required
                      single-line
                      outlined
                      dense
                      v-model="getProfile.district"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--16">จังหวัด</p>
                  <p
                    class="text--regular--16"
                    v-if="!isEditDesktop && !isEditMobileAddress"
                  >
                    {{ getProfile.province || "" }}
                  </p>
                  <div
                    class="userprofile--edit--text"
                    v-if="isEditDesktop || isEditMobileAddress"
                  >
                    <v-text-field
                      class="text--regular--placeholder--14"
                      required
                      single-line
                      outlined
                      dense
                      v-model="getProfile.province"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--16">รหัสไปรษณีย์</p>
                  <p
                    class="text--regular--16"
                    v-if="!isEditDesktop && !isEditMobileAddress"
                  >
                    {{ getProfile.zipcode || "" }}
                  </p>
                  <div
                    class="userprofile--edit--text"
                    v-if="isEditDesktop || isEditMobileAddress"
                  >
                    <v-text-field
                      class="text--regular--placeholder--14"
                      required
                      single-line
                      outlined
                      dense
                      type="number"
                      v-model="getProfile.zipcode"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <div
            v-if="isEditMobileAddress"
            class="userprofile--button--submit--info"
          >
            <v-btn
              elevation="0"
              medium
              rounded
              height="50"
              width="217"
              color="primary"
              @click="onSubmitEditProfile"
              class="pl-16 pr-16"
            >
              <span style="padding: 16px 47px 13px 48px; color: black;"
                >บันทึก</span
              ></v-btn
            >
          </div>
        </div>

        <!-- <div class="text-center hidden-sm-and-down" style="margin-top:42px"> -->
        <div class="text-center" style="margin-top:42px">
          <v-btn
            v-if="!isEditDesktop"
            rounded
            outlined
            elevation="0"
            class="text--regular--14"
            height="50"
            width="204"
            @click="onEditProfileDesktop"
          >
            <span style="padding: 16px 47px 13px 48px;"
              >ส่งคำขอแก้ไขข้อมูล</span
            >
          </v-btn>
          <v-btn
            v-if="isEditDesktop"
            elevation="0"
            medium
            rounded
            height="50"
            width="240"
            color="primary"
            @click="onSubmitEditProfile"
            class="pl-16 pr-16"
          >
            <span style="padding: 16px 47px 13px 48px; color: black;"
              >ส่งคำขอแก้ไขข้อมูล</span
            ></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mask } from "vue-the-mask";
// import VList from "../components/VList.vue";

export default {
  directives: { mask },
  // components: {
  //   VList
  // },
  created() {
    this.getUserProfile();
  },
  computed: {
    ...mapState({
      getProfile: state => {
        return { ...state.user.userProfile };
      }
    })
  },
  data: function() {
    return {
      loginType: localStorage.getItem("logintype"),
      isEditDesktop: false,
      isEditMobileInfo: false,
      isEditMobileAddress: false,
      isUpdateProsuccess: null,
      errorMessage: "",
      valid: false,
      firstname: "",
      lastname: "",
      nameRules: [
        v => !!v || "Name is required",
        v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ]
    };
  },
  methods: {
    ...mapActions("user", ["getUserProfile", "updateProfile"]),
    onEditProfileDesktop() {
      this.isEditDesktop = true;
    },
    onEditProfileMobileInfo() {
      this.isEditMobileInfo = true;
    },
    onEditProfileMobileAddress() {
      this.isEditMobileAddress = true;
    },
    async onSubmitEditProfile() {
      let editProfileHistory = { ...this.getProfile };
      delete editProfileHistory.editProfileHistory;
      if (editProfileHistory.mobile) {
        editProfileHistory.mobile = editProfileHistory.mobile
          .split("-")
          .join("");
      }
      let updateUserProfile = {
        ...this.$store.state.user.userProfile,
        editProfileHistory: editProfileHistory,
        created_date: new Date()
      };
      let response = await this.updateProfile(updateUserProfile);
      if (response) {
        if (response.status === 204) {
          this.isUpdateProsuccess = true;
        } else {
          this.errorMessage = response.error.message;
          this.isUpdateProsuccess = false;
        }
      } else {
        this.errorMessage = response.error.message;
        this.isUpdateProsuccess = false;
      }
      window.scrollTo(0, 0);
      this.isEditDesktop = false;
      this.isEditMobileInfo = false;
      this.isEditMobileAddress = false;
    },
    onClickForgotPassword() {
      this.$router.push("/forgotpassword/resetpassword");
    }
  }
};
</script>
<style>
.user-profile-header {
  font-weight: 700;
  font-size: 24px;
}

.user-profile-address-information {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 24px;
}

@media (max-width: 480px) {
  .userprofile--edit--text {
    margin-top: 7px;
  }
  .userprofile--success--dialog {
    margin-top: 11px;
    background-color: #f5d157;
    height: 70px;
    display: none !important;
  }
  .userprofile--fail--dialog {
    margin-top: 11px;
    background-color: #b71c1c;
    height: 70px;
    display: none !important;
  }
  .userprofile--parent--container {
    margin-bottom: unset;
    margin-top: unset;
  }
  .userprofile--title--text {
    display: none;
  }
  .userprofile--divider {
    margin-top: 19px;
  }
  .userprofile--info--title {
    margin-bottom: 17px;
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
  }
  .user-profile-personal-button {
    width: 170px !important;
    height: 45px !important;
  }
  .user-profile-personal-information {
    font-weight: 700;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .userprofile--container {
    padding: 29px 30px 42px 25px;
  }
  .userprofile--password--container {
    width: 100%;
    justify-content: space-between;
  }
  .userprofile--info--address {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 25px;
  }
  .userprofile--button--submit--info {
    text-align: center;
    margin-top: 31px;
    margin-bottom: 59px;
  }
  .userprofile--info--text {
    margin-bottom: 24px;
  }
  .userprofile--row--info {
    margin-top: 30px !important;
  }
  /* .userprofile--fail--dialog--text--mobile {
  } */
}

@media (min-width: 481px) {
  .userprofile--edit--text {
    margin-top: 11px;
  }
  .userprofile--success--dialog {
    margin-top: 11px;
    background-color: #f5d157;
    height: 70px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .userprofile--fail--dialog {
    margin-top: 11px;
    background-color: #b71c1c;
    height: 70px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .userprofile--success--dialog--text {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .userprofile--fail--dialog--text--mobile {
    display: none;
  }
  .userprofile--parent--container {
    margin-bottom: 67px;
    margin-top: 79px;
  }

  .userprofile--divider {
    margin-top: 19px;
  }
  .userprofile--info--title {
    margin-bottom: 22px;
    margin-top: 38px;
  }
  .user-profile-personal-information {
    font-weight: 700;
    font-size: 20px;
  }
  .userprofile--container {
    padding: 28px 44px 33px 44px !important;
  }
  /* .userprofile--password--container {
  } */
  .userprofile--info--address {
    margin-bottom: 16px;
    margin-top: 25px;
  }
  .userprofile--parent--container {
    margin-bottom: 67px;
    margin-top: 79px;
  }
  .userprofile--v-list--custom {
    display: none;
  }
  .userprofile--vlist--cmp {
    display: none;
  }
}
</style>
